import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import VueVideoPlayer from 'vue-video-player'
import 'video.js/dist/video-js.css'
import 'videojs-contrib-hls'

Vue.config.productionTip = false

Vue.use(VueVideoPlayer)

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
