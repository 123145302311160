<template>
  <div class="home">
    <video-player class="video-player-box" ref="videoPlayer" :options="playerOptions">
    </video-player>
  </div>
</template>

<script>

export default {
  data() {
    return {
      playerOptions: {
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        autoplay: false, //如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 导致视频一结束就重新开始。
        preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: 'zh-CN',
        fluid: false,
        sources: [{
          type: "application/x-mpegURL",
          src: ""
        }],
        poster: "",
        width: document.documentElement.clientWidth,
        height: document.documentElement.clientHeight,
        controlBar: {
          // timeDivider: true,
          // durationDisplay: true,
          // remainingTimeDisplay: false,
          fullscreenToggle: true  //全屏按钮
        }
      }
    }
  },
  mounted() {
    let { id } = this.$route.params;
    this.playerOptions.sources[0].src = `https://yahe-088.oss-cn-shenzhen.aliyuncs.com/wechat/${id}/output.m3u8`
  },
  computed: {
    player() {
      return this.$refs.videoPlayer.player
    }
  },
  methods: {
  }
}
</script>

<style scoped>
/deep/ .video-js .vjs-big-play-button {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  width: 1.5em;
}


/deep/ .video-js .vjs-tech {
  object-fit: cover;
}

/deep/ .vjs-poster {
  background-size: cover;
}
</style>